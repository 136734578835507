.multi-tab .info-heading {
    color: #041f3e;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #041f3e;
}

.multi-tab .details-listing {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E9ECEF;
    margin-bottom: 5px;
}


.multi-tab .details-container {
    height: 480px;
    padding: 15px;
    overflow: scroll;
}

.multi-heading-box {
    background: #F24E1E;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
}

.details-listing-label {
    color: #6C757D;
    font-size: 16px;
    font-weight: 400;
}

.details-listing-value {
    color: #000072;
    font-size: 16px;
    font-weight: 400;
}

.multi-heading {
    color: #133F72;
    font-weight: 400;
    font-size: 18px;
}

.details-heading{
    color: #212529;
    font-size: 16px;
    font-weight: 600;
}

.multi-map-container{
    position: relative;
    min-height: 550px;
}
.multi-map-legends{
    position: absolute;
    left: 15px;
    bottom: 15px;
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    width: 200px;
}
.properties-legend-box{
    width: 14px;
    height: 14px;
    margin-right: 5px;
    display: inline-block;
    margin-top: 10px;
}
.properties-legend-text {
    /* color: #6C757D; */
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.multi-heading {
    background: #FAFAFA;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
}
.multi-heading-box{
    border-radius: 3px;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    display: inline-block;
}
.multi-heading-label {
    color: #133F72;
    font-weight: 400;
    font-size: 18px;
}

@media (max-width: 600px) {
    .multi-map-container{
        min-height: 200px;
    }
}