.body-container {
    margin: 20px;
}
.table-container{
    width: 100%;
}
.mastersheet-header {
    display: flex;
    justify-content: space-between;
}
.mastersheet-heading{
    color: #133F72;
    font-size: 20px;
    font-weight: 600;
}
.action-buttons-container .icon-wrapper {
    display: inline-block;
    width: 35px;
    height: 35px;
    padding: 10px;
    border-radius: 5px;
    background: #1BAEDC;
    margin-right: 15px;
    border: 1px solid #1C9FC9;
    cursor: pointer;
}
.action-buttons-container .delete-icon {
    background: #D03838;
    border: 1px solid #D03838;
}
.action-buttons-container .print-icon {
    border: 1px solid #BEC1C4;
    background: #fff;
}
.action-buttons-container {
    display: flex;
    justify-content: center;
}
.action-buttons-container img {
    width: 35px;
}
/* .ant-modal-confirm-btns .ant-btn-dangerous {
    border: none !important;
    margin-inline-start: 0px !important;
}
.ant-modal-confirm-btns .ant-btn-default:hover {
    color: none !important;
    border-color: none !important;
} */