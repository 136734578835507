.portfolio .info-heading {
    color: #041f3e;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #949494;
}
.property-list-container {
    margin-top: 10px;
}
.properties-container {
    position: relative;
}
.properties-container .heading{
    color: #041F3E;
    font-size: 16px;
    font-weight: 600;
}
.properties-container .label{
    /* color: #6C757D; */
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}
.properties-container .value{
    color: #3A819F;
    font-size: 16px;
    font-weight: 600;
}

.pie-text1 {
    color: #515151;
    font-size: 14px;
    font-weight: 600;
}
.pie-text2 {
    color: #0E5574;
    font-size: 24px;
    font-weight: 600;
}
.properties-legend-box{
    width: 14px;
    height: 14px;
    margin-right: 5px;
    display: inline-block;
    margin-top: 10px;
}
.properties-legend-text {
    color: #6C757D;
    font-size: 16px;
    font-weight: 500;
}
.recharts-wrapper {
    height: 260px !important;
}
.legend-container{
    margin-left: 65px;
}
.map-container{
    position: relative;
    min-height: 370px;
}
.map-legends{
    position: absolute;
    left: 15px;
    bottom: 15px;
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    width: 200px;
}


.financial-container .heading {
    /* color: #767676; */
    color: #000;
    font-size: 18px;
    font-weight: 600;
    /* text-align: center; */
}

.portfolio-notes-container {
    background: #FFEDB5;
    padding: 15px;
    max-height: 300px;
    overflow: scroll;
}
.note-header-left{
    flex:4;
}
.note-header-right{
    flex:2;
}
.collapse-header{
    display: flex;
    justify-content: space-between;
}

.filter-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px; 
}

.filter-input, .filter-select {
    width: 160px;
}


.chart-container {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: flex-end;
}
.custom-tooltip .label {
    color: #fff !important;
    font-size: 14px;
}
.popover-btn-container{
    text-align: center;
    margin-top: 20px;
}
.non-interactive {
    pointer-events: none;
  }

  .ant-popover-inner-content {
    max-height: 200px !important;
    overflow: scroll !important;
  }
/* .ant-popover {
    min-width: 200px !important;
    min-height: 32px;
    max-height: 300px !important;
    overflow: scroll !important;
    padding: 6px 8px;
    color: #fff !important;
    text-align: start;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0,0,0,.85);
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 rgba(0,0,0,.08), 0 3px 6px -4px rgba(0,0,0,.12), 0 9px 28px 8px rgba(0,0,0,.05);
    box-sizing: border-box;
    text-align: center;
} */

@media (max-width: 600px) {
    .filter-container{
        justify-content: center;
    }
}