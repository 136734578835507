.header {
    padding: 16px;
    height: 50px;
    margin-bottom: 30px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
    background: #fff;
  }
  
  .header-left {
    display: flex;
    align-items: center;
  }
  
  .header-center {
    display: flex;
    justify-content: center;
  }
  
  .menu {
    display: flex;
    align-items: center;
  }
  
  .role {
    margin-left: 8px;
  }
  
  .user .ant-btn {
    margin-left: 10px;
  }
  
  .menu-item {
    display: flex;
    align-items: center;
    margin-right: 5px;
    text-decoration: none;
  }
  
  .menu li {
    margin-right: 20px;
  }
  
  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .user {
    display: flex;
    align-items: center;
  }
  
  .role {
    margin-left: 8px;
  }
  
  .dropdown-menu {
    position: relative;
  }
  
  .dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    position: absolute;
    top: 100%;
    right: 0;
    display: none;
  }
  
  .dropdown-menu ul li {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-menu ul li:hover {
    background-color: #f5f5f5;
  }
  
  .dropdown-menu:hover ul {
    display: block;
  }
  
  .button-prefix {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 20px;
    text-decoration: none;
    border: none;
    background-color: transparent;
    color: #666666;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
  }
  
  .active-button-prefix {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 20px;
    text-decoration: none;
    border: none;
    background: #888888;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
  }
  
  .button-prefix img, .active-button-prefix img {
    margin-right: 5px;
  }
  
  @media screen and (max-width: 767px) {
    .header {
      padding: 10px;
      height: 150px;
    }

    .header-right {
        margin-top: 15px;
    }

    .active-button-prefix, .button-prefix{
        font-size: 12px;
        padding: 10px;
    }
  
    .menu-item {
      margin-right: 8px;
    }
  
    .header-left {
      flex: 1;
      display: flex;
      align-items: center;
    }
  
    .header-center {
      flex: 2;
      display: flex;
      justify-content: center;
    }
  
    .header-right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  