.body-container {
    margin: 20px;
    min-height: 550px;
}
.print-img-wrapper {
    border: 1px solid #BEC1C4;
    border-radius: 10px;
    padding: 5px 5px 0px 5px;
    margin-bottom: 0px;
    width: 20px;
    cursor: pointer;
}

.ant-tabs-tab {
    background: #041f3e !important;
    color: #fff !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
    background: #54A912 !important;
    color: #fff !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
}
@media (max-width: 600px) {
    
}